<template>
  <div @mouseover="hover = true" @mouseleave="hover = false" class="tooltip__container cursor-pointer  x">
    <InformationCircleIcon class="tooltip__icon--info" />
    <div v-if="hover" class="tooltip__wrapper">
      <p v-html="message" class="tooltip__message" />
      <div class="tooltip__arrow"></div>
    </div>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";
export default {
  props: ["message"],
  components: {
    InformationCircleIcon
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
<style scoped>
  @import './tooltip.css'; 
</style>